import React from "react";

import Page from "@jumbo/shared/Page";
import ViewReferrals from "app/pages/referrals/viewreferrals";
import Investment from "app/pages/investment/investment";
import Signup from "app/pages/auth-pages/signup/signup";

import UserProfile from "app/pages/users/user-profile/UserProfile";

import ReferralBonusReport from "app/pages/reports/refferal/ReferralBonusReport";

import Logout from "app/pages/auth-pages/logout/Logout";
import Payout from "app/pages/payout/payout";
import PayoutReports from "app/pages/reports/payout/PayoutReports";
import RoiReport from "app/pages/reports/roi/roi";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";
import BinaryTree from "app/pages/treeview/BinaryTree";
import Test from "app/pages/Test";
import NonDisclosure from "app/pages/nondisclosure/NonDisclosure";
import Ecommerce from "app/pages/ecommerce/Ecommerce";
import Cart from "app/pages/cart/Cart";
import Checkout from "app/pages/checkout/Checkout";
import Payment from "app/pages/payment/Payment";
import Login from "app/pages/auth-pages/login/Login";
import OrderHistory from "app/pages/Order/OrderHistory";
import Crypto from "app/pages/dashboard/Crypto";
import UniLevelBonus from "app/pages/reports/unilevel/UnilevelReport";
import Success from "app/pages/success/Success";
import News from "app/pages/news/news"

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [



];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={Crypto} />
    },
    {
        path: "/dashboard",
        element: <Page component={Crypto} />
    },
    {
        path: "/ecommerce",
        element: <Page component={Ecommerce} />
    },
    {
        path: "/cart",
        element: <Page component={Cart} />
    },
    {
        path: "/success/:orderid/:activestatus",
        element: <Page component={Success} />
    },  
    {
        path: "/checkout",
        element: <Page component={Checkout} />
    },
    {
        path: "/payment",
        element: <Page component={Payment} />
    },
    {
        path: "/donation",
        element: <Page component={Investment} />
    },
    {
        path: "/referrals",
        element: <Page component={ViewReferrals} />
    },    
    {
        path: "/profile",
        element: <Page component={UserProfile} />
    },
    {
        path: "/unilevel-summary",
        element: <Page component={UniLevelBonus} />
    },
    {
        path: "/referral-bonus",
        element: <Page component={ReferralBonusReport} />
    },
    {
        path: "/orderhistory",
        element: <Page component={OrderHistory} />
    },
    {
        path: "/payout-summary",
        element: <Page component={PayoutReports} />
    },
    {
        path: "/roi-summary",
        element: <Page component={RoiReport} />
    },
    {
        path: "/binary-tree",
        element: <Page component={BinaryTree} />
    },
    {
        path: "/binary-tree/:randomcode",
        element: <Page component={BinaryTree} />
    },
    {
        path: "/payout",
        element: <Page component={Payout} />
    },
    {
        path: "/news",
        element: <Page component={News} />
    },
    {
        path: "/non-disclosure",
        element: <Page component={NonDisclosure}  />
    },
    {
        path: "/logout",
        element: <Page component={Logout} />
    },
];

/**
 routes only accessible when user is anonymous
 **/
 const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <Page component={Login}  />
    },
    {
        path: "/login/:token/:email",
        element: <Page component={Login}  />
    },
    {
        path: "/test",
        element: <Page component={Test}  />
    },
    {
        path: "/signup/:referralid",
        element: <Page component={Signup}  />
    },
    {
        path: "/signup",
        element: <Page component={Signup}  />
    },
    {
        path: "/forget-password",
        element: <Page component={ForgotPassword}  />
    },

    {
        path: "/reset-password/:token/:email",
        element: <Page component={ResetPassword}  />
    },
 ];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};