import React, { useContext } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {Chip, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
const FilesCounterCard = () => {
    const {loginUserData} = useContext(CustomProvider)
    return (
        <JumboCardQuick bgColor={'#23BCBA'} sx={{height:'100%'}}>
            <Div sx={{display: "flex",flexDirection: 'column', alignItems: 'center'}}>
                <CardMembershipIcon sx={{ fontSize: 70, color:'#fff' }}  />
                <Div sx={{ml: 2, flex: 1, textAlign:'center'}}>
                    <Typography color={'common.white'} variant={"h5"} mb={.5}>Membership Status</Typography>
                    <Chip label={loginUserData.membership} sx={{backgroundColor:'green',color:'#fff'}} />
                </Div>
            </Div>
        </JumboCardQuick>
    );
};

export default FilesCounterCard;
